import React, { useEffect, useState, useRef, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import countryList from "react-select-country-list";

import SignupSuccessModal from "../../../components/custom_modals/signup_success_modal";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { signup } from "../../../api/auth";
import { validateEmail } from "../../../utils";

const PageBusinessSignup = () => {
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get("redirectURL");
  const { setLoading, setModal, addToast, media, country } = useAppContext();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const allCountry = useMemo(() => countryList().getData(), []);
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [values, setValues] = useState({
    business_name: "",
    email: "",
    phone: "",
    business_address: "",
    business_type: "",
    contact_person: "",
    reg_number: "",
    password: "",
    confirmpassword: "",
    doubleEmail: "",
    location: "",
  });
  const [validations, setValidations] = useState({
    business_name: "",
    email: "",
    phone: "",
    business_address: "",
    business_type: "",
    contact_person: "",
    reg_number: "",
    password: "",
    confirmpassword: "",
    doubleEmail: "",
    location: "",
  });
  const [checked, setChecked] = useState({
    privacy: false,
    terms: false,
  });

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captcha, setCaptcha] = useState(false);
  const { t } = useTranslation();

  const onChangeCaptcha = (e: string | null) => {
    setCaptcha(!!e);
  };

  useEffect(() => {
    const thisYear = new Date().getFullYear();
    let _years = [];
    for (let index = 0; index < 100; index++) {
      _years.push(thisYear - index);
    }
    const defaultEmail = searchParams.get("email");
    if (defaultEmail) {
      handleChange("email", defaultEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo) navigate("/");
  }, [navigate, userInfo]);

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleSignup = () => {
    if (!checkvalidations()) return;

    setLoading(true);

    const fd = new FormData();
    fd.append("name", values.business_name);
    fd.append("email", values.email);
    fd.append("phone", values.phone);
    fd.append("location", values.location);
    fd.append("business_address", values.business_address);
    fd.append("business_type", values.business_type);
    fd.append("contact_person", values.contact_person);
    fd.append("reg_number", values.reg_number);
    fd.append("user_type", "BUSINESS");
    fd.append("password", values.password);
    fd.append("confirmpassword", values.confirmpassword);
    fd.append("media", media);
    fd.append("country", country);
    fd.append("isMobile", isMobile ? "true" : "false");

    signup(fd)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setModal({
            open: true,
            children: <SignupSuccessModal email={values.email} />,
          });
          navigate(`/signin?redirectURL=${redirectURL}`);
          return;
          // addToast('Register success. Email was sent. Please verify your email', {appearance: 'success', autoDismiss: true});
        } else {
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  const isPasswordStrong = () => {
    const str = values.password;
    if (str.length < 8) return;
    const len = str.length;
    let uppercase = 0;
    let lowercase = 0;
    let numbers = 0;
    let specials = 0;
    for (let i = 0; i < len; i++) {
      if (str[i] >= "A" && str[i] <= "Z") uppercase++;
      else if (str[i] >= "a" && str[i] <= "z") lowercase++;
      else if (str[i] >= "0" && str[i] <= "9") numbers++;
      else specials++;
    }
    return uppercase && lowercase && numbers && specials;
  };

  const initialValidation = {
    business_name: "",
    email: "",
    phone: "",
    citizenId: "",
    business_address: "",
    business_type: "",
    reg_number: "",
    password: "",
    confirmpassword: "",
    doubleEmail: "",
    contact_person: "",
    location: "",
  };

  const checkvalidations = () => {
    if (values.business_name === "") {
      setValidations({ ...initialValidation, business_name: "has-empty" });
      return false;
    } else if (values.business_address === "") {
      setValidations({ ...initialValidation, business_address: "has-empty" });
      return false;
    } else if (values.reg_number === "") {
      setValidations({ ...initialValidation, reg_number: "has-empty" });
      return false;
    } else if (values.contact_person === "") {
      setValidations({ ...initialValidation, contact_person: "has-empty" });
      return false;
    } else if (values.phone === "") {
      setValidations({ ...initialValidation, phone: "has-empty" });
      return false;
    } else if (!isValidPhoneNumber(values.phone)) {
      setValidations({ ...initialValidation, phone: "has-error" });
      return false;
    } else if (values.business_type === "") {
      setValidations({ ...initialValidation, business_type: "has-empty" });
      return false;
    } else if (values.location === "") {
      setValidations({ ...initialValidation, location: "has-empty" });
      return false;
    } else if (values.email === "") {
      setValidations({ ...initialValidation, email: "has-empty" });
      return false;
    } else if (!validateEmail(values.email)) {
      setValidations({ ...initialValidation, email: "has-danger" });
      return false;
    } else if (values.email !== values.doubleEmail) {
      setValidations({ ...initialValidation, doubleEmail: "has-error" });
      return false;
    } else if (values.password === "") {
      setValidations({ ...initialValidation, password: "has-empty" });
      return false;
    } else if (!isPasswordStrong()) {
      setValidations({ ...initialValidation, password: "has-warning" });
      return false;
    } else if (values.confirmpassword !== values.password) {
      setValidations({ ...initialValidation, confirmpassword: "has-danger" });
      return false;
    } else if (values.confirmpassword === "") {
      setValidations({ ...initialValidation, confirmpassword: "has-empty" });
      return false;
    } else {
      setValidations({ ...initialValidation });
    }

    return true;
  };

  const handlePhone = (value: string) => {
    handleChange("phone", value);
  };

  const borderStyle = (str: string) => {
    return str === "has-empty" || str === "has-danger" || str === "has-error"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : str === "has-warning"
      ? {
          border: "#FFA337 1px solid",
        }
      : {};
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="sign__container">
      <div className="sign">
        {isMobile && (
          <Link to="/" className="home__link">
            &times;
          </Link>
        )}
        <div
          className="sign__content"
          style={{ padding: isMobile ? 0 : "25px 0 0" }}
        >
          <form onKeyPress={handleKeyPress} className="sign__form">
            <p
              className="sign__main-title"
              style={{ marginTop: isMobile ? "10px" : "50px" }}
            >
              {t("sign up")}
            </p>
            <div className="sign__group">
              <p className="sign__form-label">{t("business name")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("business name")}
                style={borderStyle(validations.business_name)}
                value={values.business_name}
                onChange={(e) => handleChange("business_name", e.target.value)}
              />
              {validations.business_name === "has-empty" && (
                <span className="text-error">
                  {t("business name required")}*
                </span>
              )}
            </div>
            <div className="sign__group">
              <p className="sign__form-label">{t("business address")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("business address")}
                style={borderStyle(validations.business_address)}
                value={values.business_address}
                onChange={(e) =>
                  handleChange("business_address", e.target.value)
                }
              />
              {validations.business_address === "has-empty" && (
                <span className="text-error">
                  {t("business address required")}*
                </span>
              )}
            </div>
            <div className="sign__group">
              <p className="sign__form-label">{t("reg number")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("registration number")}
                style={borderStyle(validations.reg_number)}
                value={values.reg_number}
                onChange={(e) => handleChange("reg_number", e.target.value)}
              />
              {validations.reg_number === "has-empty" && (
                <span className="text-error">{t("reg number required")}*</span>
              )}
            </div>
            <div className="sign__group">
              <p className="sign__form-label">{t("contact person")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("name and surname")}
                style={borderStyle(validations.contact_person)}
                value={values.contact_person}
                onChange={(e) => handleChange("contact_person", e.target.value)}
              />
              {validations.contact_person === "has-empty" && (
                <span className="text-error">
                  {t("contact person required")}*
                </span>
              )}
            </div>

            <div className="sign__group">
              <p className="sign__form-label">{t("phone")}</p>
              <PhoneNumberInput
                phoneNumber={values.phone}
                handlePhone={handlePhone}
                borderStyle={borderStyle(validations.phone)}
              />
              {validations.phone === "has-empty" && (
                <span className="text-error">
                  {t("phone number required")}*
                </span>
              )}
              {validations.phone === "has-error" && (
                <span className="text-error">{t("input correct format")}*</span>
              )}
            </div>

            <div className="sign__group">
              <select
                id="gender"
                name="gender"
                className="sign__select"
                value={values.business_type}
                onChange={(e) => handleChange("business_type", e.target.value)}
                style={borderStyle(validations.business_type)}
              >
                <option disabled hidden value="">
                  {t("select business type")}
                </option>
                <option value="promoter">{t("promoter")}</option>
                <option value="venue">{t("venue")}</option>
                <option value="service provider">
                  {t("service provider")}
                </option>
                <option value="artist">{t("artist")}</option>
              </select>
              <img
                src="/img/icons/arrow-down.svg"
                alt="data"
                className="cr__signup-selectArrow"
              />
              {validations.business_type === "has-empty" && (
                <span className="text-error">
                  {t("business type required")}*
                </span>
              )}
            </div>

            <div className="sign__group">
              <select
                id="location"
                name="location"
                className="sign__select"
                value={values.location}
                onChange={(e) => handleChange("location", e.target.value)}
                style={borderStyle(validations.location)}
              >
                <option disabled hidden value="">
                  {t("select location")}
                </option>
                {allCountry.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <img
                src="/img/icons/arrow-down.svg"
                alt="data"
                className="cr__signup-selectArrow"
              />
              {validations.location === "has-empty" && (
                <span className="text-error">{t("location required")}*</span>
              )}
            </div>

            <div className="sign__group">
              <p className="sign__form-label">{t("email")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("email")}
                style={borderStyle(validations.email)}
                value={values.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {validateEmail(values.email) && (
                <img
                  src="/img/green-check-circle.png"
                  className="signup__img_eye"
                  alt="email success"
                />
              )}
              {validations.email === "has-empty" && (
                <span className="text-error">{t("email required")}*</span>
              )}
              {validations.email === "has-danger" && (
                <span className="text-error">{t("input correct format")}*</span>
              )}
            </div>

            <div className="sign__group">
              <p className="sign__form-label">{t("confirm email")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("confirm email")}
                style={borderStyle(validations.doubleEmail)}
                value={values.doubleEmail}
                onChange={(e) => handleChange("doubleEmail", e.target.value)}
              />
              {validateEmail(values.doubleEmail) &&
                values.doubleEmail === values.email && (
                  <img
                    src="/img/green-check-circle.png"
                    className="signup__img_eye"
                    alt="email success"
                  />
                )}
              {validations.doubleEmail === "has-error" && (
                <span className="text-error">
                  {t("confirm email does not match")}*
                </span>
              )}
            </div>

            <div className="sign__group">
              <p className="sign__form-label">{t("password")}</p>
              <input
                type={passType}
                className="sign__input"
                placeholder={t("password")}
                style={borderStyle(validations.password)}
                value={values.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              {passType === "password" && (
                <img
                  src="/img/eye.png"
                  onClick={() => setPassType("text")}
                  className="signup__img_eye"
                  alt="eye"
                />
              )}
              {passType === "text" && (
                <img
                  src="/img/eye-off.png"
                  onClick={() => setPassType("password")}
                  className="signup__img_eye"
                  alt="eye"
                />
              )}
              {validations.password === "has-empty" && (
                <span className="text-error">{t("password required")}*</span>
              )}
              {validations.password === "has-warning" && (
                <span className="text-error">
                  {t("strong password required")}*
                </span>
              )}
            </div>

            <div className="sign__group">
              <p className="sign__form-label">{t("confirm password")}</p>
              <input
                type={confirmPassType}
                className="sign__input"
                placeholder="Confirm Password"
                style={borderStyle(validations.confirmpassword)}
                value={values.confirmpassword}
                onChange={(e) =>
                  handleChange("confirmpassword", e.target.value)
                }
              />
              {confirmPassType === "password" && (
                <img
                  src="/img/eye.png"
                  onClick={() => setConfirmPassType("text")}
                  className="signup__img_eye"
                  alt="eye"
                />
              )}
              {confirmPassType === "text" && (
                <img
                  src="/img/eye-off.png"
                  onClick={() => setConfirmPassType("password")}
                  className="signup__img_eye"
                  alt="eye"
                />
              )}
              {validations.confirmpassword === "has-empty" && (
                <span className="text-error">{t("password required")}*</span>
              )}
              {validations.confirmpassword === "has-danger" && (
                <span className="text-error">
                  {t("confirm password does not match")}*
                </span>
              )}
            </div>

            <div className="sign__group sign__group--checkbox mb-0">
              <input
                id="remember"
                name="remember"
                type="checkbox"
                checked={checked.privacy}
                onChange={() => {
                  setChecked({ ...checked, privacy: !checked.privacy });
                }}
              />
              <label htmlFor="remember">
                {t("I agree.text1")}
                <a href="/Backstage_Privacy_Policy.pdf" target="_blank">
                  {" "}
                  {t("I agree.privacy")}
                </a>
              </label>
            </div>

            <div className="sign__group sign__group--checkbox mb-0">
              <input
                id="remember2"
                name="remember2"
                type="checkbox"
                checked={checked.terms}
                onChange={() => {
                  setChecked({ ...checked, terms: !checked.terms });
                }}
              />
              <label htmlFor="remember2">
                {t("I agree.text1")}
                <a href="/Backstage_Terms_Conditions.pdf" target="_blank">
                  {" "}
                  {t("I agree.terms")}
                </a>
              </label>
            </div>

            <ReCAPTCHA
              className="sign__recaptcha"
              ref={recaptchaRef as any}
              sitekey={process.env.REACT_APP_CAPTCHA as string}
              onChange={onChangeCaptcha}
            />
            <button
              className={
                captcha && checked.privacy && checked.terms
                  ? "sign__btn"
                  : "sign__btn-disable"
              }
              type="button"
              onClick={
                captcha && checked.privacy && checked.terms
                  ? handleSignup
                  : () => {}
              }
              // onClick={handleSignup}
            >
              {t("sign up")}
            </button>
          </form>
        </div>
      </div>
      <div className="sign_bottom"></div>
    </div>
  );
};

export default PageBusinessSignup;
